import React from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment,List } from 'semantic-ui-react'
import logo from '../logo.png'; 
import {
    Link,
    useParams
  } from "react-router-dom";
  import {apiurl}from '../constant';
  
import {AuthContext} from '../Contexts/auth';

const CertList = () => {
  
    const { cklist,logOut } = React.useContext(AuthContext);
    const [clist, setList] = React.useState([]);

    React.useEffect(() => {

        const bootstrapAsync = async () => {
          
            setList(cklist.certs);
          
        };
    
        bootstrapAsync();
    
      }, [cklist]);
    

  return <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 450 }}>
    <Image src={logo} />
      <Header as='h2' color='teal' textAlign='center'>
        {cklist.trneeName.toUpperCase()}
      </Header>
      {clist.length>0 ?
      <React.Fragment>
      List of certificates 
      <Segment>
      <List  divided relaxed>
        {
                clist.map((x, idx) =>
                  <List.Item  key={idx} as={Link} to={`/certs/${idx}`} >
                    <List.Header>{x.courseName}</List.Header>{
                        x.trainDtEnd===x.trainDtStart?x.trainDtStart:(x.trainDtStart+' - '+x.trainDtEnd)
                    }
                  </List.Item>
                )
              }
   
  </List>
  </Segment>
  </React.Fragment>:
  <Header as='h3' color='red' textAlign='center'>
  No certificate found
</Header>
  }
  <Button content='Exit' icon='left arrow' basic color="red" labelPosition='left' onClick={()=>{
    logOut();
  }} />
    </Grid.Column>
  </Grid>
}

export default CertList