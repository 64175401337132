import React from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import logo from '../logo.png'; 
import {
    Link
  } from "react-router-dom";

  
import {AuthContext} from '../Contexts/auth';
const LoginScreen = () => {
  
  const { onAuth } = React.useContext(AuthContext);
  const [ic, setIC] = React.useState(false);
  const [error, setError] = React.useState(false);

  return <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 450 }}>
    <Image src={logo} />
      <Header as='h2' color='teal' textAlign='center'>
       E-Certification System
      </Header>
      <Form size='large'>
        <Segment stacked>
          <Form.Input name="ic" fluid icon='user' iconPosition='left' placeholder='IC/Passport Number'
          onChange={(event) => {
            const value =  event.target.value;
            setIC(value);
            setError(false);
        }}
          />
       
          <Button color='teal' fluid size='large' onClick={()=>onAuth(ic).catch(e=>setError(true))} >
            Next
          </Button>
          {error && <Message negative>
            <Message.Header>Unable to proceed</Message.Header>
            <p>User or certificate not found</p>
            <p>Please enter IC No. without "-"</p>
          </Message>}
        </Segment>
      </Form>
      {/* <Message>
        New to us? <a href='#'>Sign Up</a>
      </Message> */}
    </Grid.Column>
  </Grid>
}

export default LoginScreen