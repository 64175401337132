import React from 'react'
import { Button, Icon, Grid, Header,Image as Certimage, Message, Segment,List } from 'semantic-ui-react'
import pdfMake from 'pdfmake'; 
import {
    Link,
    useParams
  } from "react-router-dom";
  import {apiurl}from '../constant';
import FlashScreen from './FlashScreen';
import {AuthContext} from '../Contexts/auth';

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

const Certificate = () => {
  
    const { cklist } = React.useContext(AuthContext);
    let { idx } = useParams();
    const [csswidth, setcsswidth] = React.useState('50vw');
    const [cssheight, setcssheight] = React.useState('50vh');
    const [newimg,setnewimg]=React.useState(null);
    const [cert, setCert] = React.useState({});
    const [download, setdownload] = React.useState(false);

    React.useEffect(() => {

        const bootstrapAsync = async () => {
          
          setCert(cklist.certs[idx]);
          loadcanvas();
          
        };
    
        bootstrapAsync();
    
      }, [cklist]);
      
      const loadcanvas =()=> {
        // const context = canvas.getContext("2d");

        const newname = cklist.trneeName.toLowerCase().split(' ').map(
          name => {
            if (name !== "binti" && name !== "bin" && name !== "b." && name !== "bte")
            {return name.charAt(0).toUpperCase() + name.slice(1);}
            else{
              return name;
            }
            
          }
        ).join(' ');
        var img = new Image;
        //img.height =  ctx.canvas.height;
        //img.width = ctx.canvas.width;
        img.crossOrigin = 'Anonymous';
        img.onload = () => {

          let canvas = document.createElement('canvas');
          let context = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          context.canvas.height = img.height;
          context.canvas.width = img.width;
          
        let fixheight = window.innerHeight*0.98;
        let fixwidth = window.innerWidth;
        let widthR = fixwidth /  img.width;
        let heightR = fixheight / img.height;


        let ratios = Math.min(widthR, heightR)
        ratios = Math.floor(ratios * 100) / 100

        var calwidth =  img.width * ratios;
        var calheight =  img.height * ratios;
        setcsswidth(calwidth);
        setcssheight(calheight);

          context.drawImage(img, 0, 0);
  
          
  
          let height=img.height;
          let width=img.width;
          let certname=newname;
  
          let namesize=width/22;
  
          if(certname.length>25){
               namesize=width/30;
          }
          
          context.font = "bold "+namesize+"px Century Gothic";
          context.fillStyle = "#6A9C40";
          context.textAlign = "center";
          context.fillText(certname,( width/2 ),(height/2.38)); 
          
          context.font = "bold "+width/55+"px Century Gothic";
          context.fillStyle = "#6A9C40";
          context.fillText(cklist.trneeIcNo,( width/2 ),(height/2.17)); 
          let traindate=new Date(cklist.certs[idx].trainDtEnd);
          var lastcertdate = new Date("2020-10-30");

         if(traindate>lastcertdate){
          context.fillStyle = "black";
          context.font = "normal "+width/59+"px Century Gothic";
          context.fillText(traindate.toLocaleDateString('ms', {  year: 'numeric', month: 'long', day: 'numeric' }),( width/2 ),(height/1.68)); 
  }
          // context.font =  width/60+"px Century Gothic";
          // context.textAlign = "right";
          // context.fillText("code",( width*0.88),0.14*height); 
  
          
          // let qrImg=$(div).find('img')[0];
          // context.drawImage(qrImg, ( width/2 )-(width*0.036), (height/1.215),width*0.036,width*0.036);
  
          let dataurl=canvas.toDataURL('image/jpeg');
          
         // $('#certimage').attr("src", dataurl);
         // cb(dataurl);
         setnewimg(dataurl);
        
        }
        let template=cklist.certs[idx].certLink
        if(template){
        img.src = template}
    }

    const  generatePDF = ()=>{
        setdownload(true);
      var dd = {
        pageSize: 'A4',
  
          // by default we use portrait, you can change it to landscape if you wish
          pageOrientation: 'landscape',
  
          // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
          pageMargins: [ 40, 60, 40, 60 ],
          background: [
          {
              image: newimg,
              fit: [841.89, 595.28]
          }
          ],
      
  }
  setTimeout(()=>pdfMake.createPdf(dd).download(cert.courseName+".pdf",()=>
  setTimeout(()=>setdownload(false), 10000)), 1000);
  }
  return <div className="flexcenter">
    {newimg && !download?
    <React.Fragment>
      <Certimage  src={newimg} style={{ width: csswidth, height: cssheight}} />
      <Button fluid color="blue" onClick={()=>generatePDF()}>
      <Icon name='download' /> Download</Button>
    </React.Fragment>
    :
    <FlashScreen message={download?"Please wait while windows downloading your file...":"Generating Certificate..."}/>
    }

  </div>
}

export default Certificate