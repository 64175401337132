import React from 'react';
import LoginScreen from  './screens/LoginScreen';
import CertList from  './screens/CertList';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import {AuthContext} from './Contexts/auth';

 import {apiurl}from './constant';
import Certificate from './screens/Certificate';
function App() {
  
//   window.onunload = () => {
//     // Clear the local storage
//       localStorage.clear()
//  }
  const [cklist, setList] = React.useState(null);

  React.useEffect(() => {

      const bootstrapAsync = async () => {
        if(localStorage.hasOwnProperty('certlist')){
          let ic=localStorage.getItem("certlist");
          getCert(ic);
        }
      };
  
      bootstrapAsync();
      
  
    }, []);
const getCert=id=>{
  return new Promise(function (resolve, reject) {
    try{
    fetch(apiurl + 'hpb.getcourselist/'+id)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        reject('Something went wrong');
      }
    })
    .then(x => {
      console.log(x)
      setList(x);
      localStorage.setItem('certlist',x.trneeIcNo)
    })
    .catch(e=>{
      console.log(e);
      reject(e);
    })
    }
    catch(e){
      reject(e);
    }
  });
 
}
  const authContext = React.useMemo(
    () =>({
            onAuth:id=>{
              return new Promise(function (resolve, reject) {
              getCert(id).catch(e=>reject(e));
              });
                  },
            logOut:()=>{
              localStorage.clear();
              setList(null);
              },
              cklist
  }),
    [cklist]
);
  return (
    
    <AuthContext.Provider value={authContext}>
      {
      cklist? 
        <Switch>
          <Route path="/certs/:idx">
            <Certificate/>
          </Route>
          <Route path="/">
            <CertList/>
          </Route>
        </Switch>
        :<LoginScreen/>
      }
        </AuthContext.Provider>
  );
}

export default App;
